import { createSlice } from '@reduxjs/toolkit'
import { Branch } from 'src/interfaces/branches'
import { Provider, ProviderServices } from 'src/interfaces/providers'

const serviceProviders: Provider[] = []
const providerBranches: Branch[] = []
const providerServices: ProviderServices[] = []
const selectedProvider = {} as Provider
const offset = 0
const limit = 100
const initialState = {
  serviceProviders,
  selectedProvider,
  searchInput: '',
  providerBranches,
  providerServices,
  offset,
  limit
}

const serviceProvidersSlice = createSlice({
  name: 'Service Provider',
  initialState,
  reducers: {
    setOffset:(state, {payload})=>{
      state.offset = payload
    },
    setLimit:(state, {payload})=>{
      state.limit = payload
    },
    allProviders: (state, { payload }) => {
      state.serviceProviders = payload
    },
    selectProvider: (state, { payload }) => {
      state.selectedProvider = payload
    },
    setProviderServices: (state, { payload }) => {
      state.providerServices = payload
    },
    setProviderBranches: (state, { payload }) => {
      state.providerBranches = payload
    },
    searchProvider: (state, { payload }) => {
      state.searchInput = payload
    },
  },
})
export const {
  allProviders,
  selectProvider,
  searchProvider,
  setProviderBranches,
  setProviderServices,
  setOffset,
  setLimit
} = serviceProvidersSlice.actions
export default serviceProvidersSlice.reducer
