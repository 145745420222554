/* eslint-disable camelcase */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useQuery } from '@tanstack/react-query';
import { Modal, Button, Alert } from 'react-bootstrap';
import Select, { MultiValue } from 'react-select';
import { AppDispatch, AppState } from 'src/store';
import * as providersSlice from '../../store/ServiceProvidersSlice';
import * as servicesSlice from '../../store/ServicesSlice';
import * as branchSlices from '../../store/BranchesSlice';
import * as providerService from '../../services/providerServices';
import * as serviceServices from '../../services/serviceServices';
import * as branchServices from '../../services/branchServices';
import InputContainer from '@components/form/InputContainer';
import FormButton from '@components/form/FormButton';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import * as texts from '../exceptions/texts';
import { ServiceOptions } from 'src/interfaces/services';
import { BranchOptions } from 'src/interfaces/branches';
import { Provider } from 'src/interfaces/providers';

type Props = {
  show: boolean;
  onHide: () => void;
};

const CreateProviderModal = (props: Props) => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch<AppDispatch>();
  const [error, setError] = useState(false);
  const [serviceOptions, setServiceOptions] = useState<ServiceOptions[]>([]);
  const [branchOptions, setBranchesOptions] = useState<BranchOptions[]>([]);
  const [selectedProviderServices, setSelectedProviderServices] = useState<
    MultiValue<ServiceOptions>
  >([]);
  const [selectedProviderBranches, setSelectedProviderBranches] = useState<
    MultiValue<BranchOptions>
  >([]);
  const { offset, limit, selectedProvider } = useSelector((state: AppState) => state.providers);
  const [isSaving, setIsSaving] = useState(false);

  const { isError, isSuccess, isFetching } = useQuery(
    ['allServices'],
    () => serviceServices.getAllServices(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (services) => {
        dispatch(servicesSlice.allServices(services));

        const transformedServices = services.map((eachService: { id: number; name: string }) => {
          const serviceOptionsDropdown = {} as ServiceOptions;
          serviceOptionsDropdown.service_id = eachService.id as number;
          serviceOptionsDropdown.label = eachService.name;
          serviceOptionsDropdown.value = eachService.name.toLowerCase();
          return serviceOptionsDropdown;
        });

        setServiceOptions(transformedServices);
      },
    },
  );
  const allBranches = useQuery(['allBranches'], () => branchServices.getAllBranches(), {
    refetchOnWindowFocus: false,
    onSuccess: (branches) => {
      dispatch(branchSlices.allBranches(branches));

      const transformedBranches = branches.map((eachBranch: { id: number; name: string; }) => {
        const branchOptionsDropdown = {} as BranchOptions;
        branchOptionsDropdown.branch_id = eachBranch.id as number;
        branchOptionsDropdown.label = eachBranch.name;
        branchOptionsDropdown.value = eachBranch.name.toLowerCase();

        return branchOptionsDropdown;
      });
      setBranchesOptions(transformedBranches);
    },
  });

  const [provider, setProvider] = useState<Provider>({
    name: '',
    desc: '',
    code: '',
    email: '',
    phone: '',
    provider_image: '',
    is_visible: true,
    enable_google_sync: true,
    client_limit: '',
    create_username: keycloak?.tokenParsed?.preferred_username,
  });

  const handleCreateProvider = async () => {
    setIsSaving(true);
    if (
      provider.name.length > 0 &&
      provider.desc.length > 0 &&
      provider.code.length > 0 &&
      provider.email.toString().trim() !== '' &&
      provider.client_limit.trim() !== ''
    ) {
      const createdProvider = await providerService.createProvider(provider);
      for (const eachProviderBranch of selectedProviderBranches) {
        const providerBranches = {
          branch_id: eachProviderBranch.branch_id,
          provider_id: createdProvider.id,
        };
        await providerService.updateProviderBranches(providerBranches);
      }

      for (const eachProviderService of selectedProviderServices) {
        const providerServices = {
          service_id: eachProviderService.service_id,
          provider_id: createdProvider.id,
        };
        await providerService.updateProviderServices(providerServices);
      }

      const fetchProviders = await providerService.getAllProviders(offset, limit);
      if (selectedProvider === undefined) {
        dispatch(providersSlice.selectProvider(fetchProviders[0]));
      }
      dispatch(providersSlice.allProviders(fetchProviders));

      setIsSaving(false);
      setError(false);
      setProvider({
        name: '',
        desc: '',
        code: '',
        email: '',
        phone: '',
        provider_image: '',
        is_visible: true,
        enable_google_sync: true,
        client_limit: '',
        create_username: keycloak?.tokenParsed?.preferred_username,
      });
      props.onHide();
    } else {
      setIsSaving(false);
      setError(true);
    }
  };
  return (
    <Modal size='lg' {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Create Service Provider</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isFetching && (
          <div className='text-center'>
            <LoadingSpinner />
          </div>
        )}
        {isError && (
          <Alert variant='light' className='label m-0 text-danger'>
            <i className='eha-level4 text-danger me-2' />
            {texts.FETCHING_FAILED}
          </Alert>
        )}
        {isSuccess && allBranches.isSuccess && (
          <>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='name' label='Provider Name' required={true}>
                  <input
                    type='text'
                    defaultValue={provider.name}
                    onChange={(item) =>
                      setProvider({
                        ...provider,
                        name: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='code' label='Provider Code' required={true}>
                  <input
                    type='text'
                    defaultValue={provider.code}
                    onChange={(item) => setProvider({ ...provider, code: item.target.value })}
                  />
                </InputContainer>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='email' label='Email' required={true}>
                  <input
                    type='email'
                    defaultValue={provider.email}
                    onChange={(item) =>
                      setProvider({
                        ...provider,
                        email: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='phone' label='Phone Number'>
                  <input
                    type='number'
                    defaultValue={provider.phone}
                    onChange={(item) => setProvider({ ...provider, phone: item.target.value })}
                  />
                </InputContainer>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='client' label='Client Limit' required={true}>
                  <input
                    type='number'
                    defaultValue={provider.client_limit}
                    onChange={(item) =>
                      setProvider({
                        ...provider,
                        client_limit: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='image' label='Provider Image'>
                  <input
                    type='text'
                    defaultValue={provider.provider_image}
                    onChange={(item) =>
                      setProvider({ ...provider, provider_image: item.target.value })
                    }
                  />
                </InputContainer>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='services' label='Services'>
                  <Select
                    instanceId='Service'
                    isMulti
                    onChange={(service) => setSelectedProviderServices(service)}
                    options={serviceOptions}
                    className='select'
                    classNamePrefix='select'
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='location' label='Branches'>
                  <Select
                    instanceId='Branches'
                    isMulti
                    onChange={(branch) => setSelectedProviderBranches(branch)}
                    options={branchOptions}
                    className='select'
                    classNamePrefix='select'
                  />
                </InputContainer>
              </div>
            </div>
            <InputContainer name='Description' label='Description' required={true}>
              <textarea
                defaultValue={provider.desc}
                onChange={(item) => setProvider({ ...provider, desc: item.target.value })}
                rows={2}
              ></textarea>
            </InputContainer>
            <div className='text-danger text-center my-2'>
              {error && 'One or more items is required'}
            </div>
          </>
        )}
      </Modal.Body>
      {isSuccess && (
        <Modal.Footer>
          <Button variant='light' onClick={props.onHide}>
            Cancel
          </Button>
          <FormButton loading={isSaving} onClick={handleCreateProvider}>
            Create
          </FormButton>
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default CreateProviderModal;
