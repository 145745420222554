import { MultiValue } from 'react-select';
import { ServiceOptions } from 'src/interfaces/services';

export const getAddedServices =(existingServices: MultiValue<ServiceOptions>, serviceOptions: MultiValue<ServiceOptions>)=>{
    return serviceOptions.filter((newService: ServiceOptions) => 
      !existingServices.some(currentService => currentService.service_id === newService.service_id)
    );
  }

export const getRemovedServices = (existingServices: MultiValue<ServiceOptions>, serviceOptions: MultiValue<ServiceOptions>) => {
    return existingServices.filter((currentService: ServiceOptions) => 
      !serviceOptions.some(newService => newService.service_id === currentService.service_id)
    );
  }
