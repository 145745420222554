import { MultiValue } from 'react-select';
import { BranchOptions } from 'src/interfaces/branches';

export const getAddedBranches =(existingBranches: MultiValue<BranchOptions>, branchOptions: MultiValue<BranchOptions>)=>{
    return branchOptions.filter((newBranch: BranchOptions) => 
      !existingBranches.some(currentBranch => currentBranch.branch_id === newBranch.branch_id)
    );
  }

export const getRemovedBranches = (existingBranches: MultiValue<BranchOptions>, branchOptions: MultiValue<BranchOptions>) => {
    return existingBranches.filter((currentBranch: BranchOptions) => 
      !branchOptions.some(newBranch => newBranch.branch_id === currentBranch.branch_id)
    );
  }
