/* eslint-disable camelcase */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useQuery } from '@tanstack/react-query';
import { Modal, Button, Alert } from 'react-bootstrap';
import Select, { MultiValue } from 'react-select';
import { AppDispatch, AppState } from 'src/store';
import * as servicesSlice from '../../store/ServicesSlice';
import * as branchSlices from '../../store/BranchesSlice';
import * as serviceService from '../../services/serviceServices';
import * as branchServices from '../../services/branchServices';
import InputContainer from '@components/form/InputContainer';
import FormButton from '@components/form/FormButton';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import * as texts from '../exceptions/texts';

type Props = {
  show: boolean;
  onHide: () => void;
};
interface BranchOptions {
  branch_id: number;
  label: string;
  value: string;
}
const CreateServiceModal = (props: Props) => {
  const { keycloak } = useKeycloak();

  const dispatch = useDispatch<AppDispatch>();
  const [branchOptions, setBranchOptions] = useState<BranchOptions[]>([]);
  const [selectedServiceBranches, setSelectedServiceBranches] = useState<MultiValue<BranchOptions>>(
    [],
  );
  const selectedService = useSelector((state: AppState) => state.services.selectedService);
  const [error, setError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [createService, setCreateService] = useState<servicesSlice.Service>({
    name: '',
    desc: '',
    code: '',
    icon: '',
    color: '',
    duration: '',
    limit_booking: '',
    create_username: keycloak?.tokenParsed?.preferred_username,
  });

  const { isFetching, isSuccess, isError } = useQuery(
    ['allBranches'],
    () => branchServices.getAllBranches(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (branches) => {
        dispatch(branchSlices.allBranches(branches));

        const transformedBranches = branches.map((eachBranch: { id: number; name: string }) => {
          const branchOptionsDropdown = {} as BranchOptions;
          branchOptionsDropdown.branch_id = eachBranch.id as number;
          branchOptionsDropdown.label = eachBranch.name;
          branchOptionsDropdown.value = eachBranch.name.toLowerCase();

          return branchOptionsDropdown;
        });
        setBranchOptions(transformedBranches)
      },
    },
  );

  const createServiceDetails = async () => {
    if (
      createService.desc.length > 0 &&
      createService.name.length > 0 &&
      createService.code.length > 0 &&
      createService.duration.toString().trim() !== '' &&
      createService.limit_booking.toString().trim() !== ''
    ) {
      setIsSaving(true);
      const createdService = await serviceService.createService(createService);
      for (const eachServiceBranch of selectedServiceBranches) {
        const serviceBranches = {
          branch_id: eachServiceBranch.branch_id,
          service_id: createdService.id,
        };
        await serviceService.updateServiceBranches(serviceBranches);
      }

      const fetchServices = await serviceService.getAllServices();
      if (selectedService === undefined) {
        dispatch(servicesSlice.selectService(fetchServices[0]));
      }
      dispatch(servicesSlice.allServices(fetchServices));
      setCreateService({
        name: '',
        desc: '',
        code: '',
        color: '',
        icon: '',
        duration: '',
        limit_booking: '',
        create_username: keycloak?.tokenParsed?.preferred_username,
      });
      setIsSaving(false);
      setError(false);
      props.onHide();
    } else {
      setError(true);
    }
  };

  return (
    <Modal size='lg' {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Create Service</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isFetching && (
          <div className='text-center'>
            <LoadingSpinner />
          </div>
        )}
        {isError && (
          <Alert variant='light' className='label m-0 text-danger'>
            <i className='eha-level4 text-danger me-2' />
            {texts.FETCHING_FAILED}
          </Alert>
        )}
        {isSuccess && (
          <>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='name' label='Service Name' required={true}>
                  <input
                    type='text'
                    defaultValue=''
                    onChange={(item) =>
                      setCreateService({
                        ...createService,
                        name: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-3'>
                <InputContainer name='icon' label='Service Icon'>
                  <input
                    type='text'
                    defaultValue={createService.icon}
                    onChange={(item) =>
                      setCreateService({ ...createService, icon: item.target.value })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-3'>
                <InputContainer name='code' label='Service Code' required={true}>
                  <input
                    type='text'
                    defaultValue=''
                    onChange={(item) =>
                      setCreateService({ ...createService, code: item.target.value })
                    }
                  />
                </InputContainer>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='duration' label='Service Duration' required={true}>
                  <div className='d-flex align-items-center'>
                    <input
                      type='number'
                      defaultValue={createService.duration}
                      onChange={(item) =>
                        setCreateService({
                          ...createService,
                          duration: item.target.value,
                        })
                      }
                    />
                    <span className=' '>Minutes</span>
                  </div>
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='booking' label='Limit Booking' required={true}>
                  <div className='d-flex align-items-center'>
                    <input
                      type='number'
                      defaultValue={createService.limit_booking}
                      onChange={(item) =>
                        setCreateService({
                          ...createService,
                          limit_booking: item.target.value,
                        })
                      }
                    />
                  </div>
                </InputContainer>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='location' label='Branches'>
                  <Select
                    instanceId='Branches'
                    isMulti
                    onChange={(branch) => setSelectedServiceBranches(branch)}
                    options={branchOptions}
                    className='select'
                    classNamePrefix='select'
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='color' label='Color Code'>
                  <input
                    type='text'
                    defaultValue=''
                    onChange={(item) =>
                      setCreateService({ ...createService, color: item.target.value })
                    }
                  />
                </InputContainer>
              </div>
            </div>
            <InputContainer name='Description' label='Description' required={true}>
              <textarea
                defaultValue={createService.desc}
                onChange={(item) => setCreateService({ ...createService, desc: item.target.value })}
                rows={2}
              ></textarea>
            </InputContainer>
            <div className='text-danger text-center'>
              {error && 'One or more items is required'}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={props.onHide}>
          Cancel
        </Button>
        <FormButton loading={isSaving} onClick={createServiceDetails}>
          Create
        </FormButton>
      </Modal.Footer>
    </Modal>
  );
};
export default CreateServiceModal;
