import moment from 'moment'

export function reformatDate(date: string) {
  const momentDate = moment(date)
  if (momentDate.isValid()) {
    const formattedDate = moment(date)
    const dateComponent = formattedDate.format('D MMM YYYY')
    const timeComponent = formattedDate.format('h:mma')

    return {
      date: dateComponent,
      time: timeComponent,
    }
  } else {
    return {
      date: '',
      time: '',
    }
  }
}

export function reformatDateFilter(dateObject: { from: string; to: string }) {
  if (dateObject.from && dateObject.to) {
    return {
      from: moment(dateObject.from).format('YYYY-MM-DD'),
      to: moment(dateObject.to).format('YYYY-MM-DD'),
    }
  } else {
    return {
      from: '',
      to: '',
    }
  }
}

export const dateToHrsMins = (start: string, end: string) => {
  const time1 = new Date(start)
  const time2 = new Date(end)

  const diff = time2.getTime() - time1.getTime()

  const getMins = Math.floor(diff / 1000 / 60)
  let getHrs
  let rem

  if (getMins > 59) {
    getHrs = Math.floor(getMins / 60)
    rem = getMins % 60
    return `${getHrs}${getHrs > 1 ? 'hrs' : 'hr'} ${rem > 0 ? rem + 'mins' : ''}`
  } else {
    return `${getMins}mins`
  }
}

export const addMinutes = (start: string | undefined, min: number | undefined) => {
  if (start && min) {
    const time1 = new Date(start)
    const addingMins = new Date(time1.getTime() + min * 60000)

    return addingMins.toISOString()
  } else return ''
}

export const formatDateTime = (dateTime: Date | string, format: string) =>
    moment(dateTime).format(format);