import { Badge } from 'react-bootstrap';
import { statusSwitch, valueSwitch } from '@utils/tableUtil';
import { Booking } from 'src/interfaces';
import { formatDateTime } from '@utils/reformatDate' 

const BookingDetails = ({ booking }: { booking: Booking }) => {
          
  return (
    <div className="booking-details-modal">
        <div className='d-flex align-items-center justify-content-between w-100'>
        <div className=''>    
        <Badge bg={statusSwitch(booking.status)}>{valueSwitch(booking.status)}</Badge>
        {booking.isActive && <Badge bg="success" className="ms-2">Active</Badge>}
        </div>
        <div className='d-flex justify-content-end'>{booking.isEmergency && <Badge bg="danger">Emergency</Badge>}
        </div>
        </div>
        <div className='d-flex align-items-center justify-content-between w-100 mt-3'>
          <div>
            <h5 className="mb-0">{booking.client?.name}</h5>
            <p className="text-muted mb-0 mt-1">{booking.client.email}</p>
          </div>
        
        <div className='d-flex align-items-end flex-column'>
          <p className="mb-0 text-muted">{booking.service}</p>
          <h6 className="text-primary mb-0 mt-1">{booking.booking_code}</h6>
        </div>

      </div>
      <hr className="service-booking-border" />

      {/* Booking Information */}
      <div className="mb-4">
        <div className="row mb-3">
          <div className="col-6">
            <strong>Provider's Name</strong>
            <p>{booking.service_provider}</p>
          </div>
          <div className="col-6">
            <strong>Branch Name</strong>
            <p>{booking.branch}</p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <strong>Created By</strong>
            <p>{booking.create_username}</p>
          </div>
          <div className="col-6">
            <strong>Date Created</strong>
            <p>{formatDateTime(booking.created_at, 'DD MMM YYYY hh:mm A')}</p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <strong>Origin</strong>
            <p>{booking.origin}</p>
          </div>
          <div className="col-6">
          <strong>Start Time</strong>
          <p>{formatDateTime(booking.appointment_date.start_time, 'DD MMM YYYY hh:mm A')} </p>
          </div>
        </div>
      </div>

      {/* Comment Section */}
      <div>
        <strong>Comment</strong>
        <p>
          {booking.comment ||
            'No comments provided for this booking. Please check additional notes if available.'}
        </p>
      </div>
    </div>
  );
};

export default BookingDetails;
