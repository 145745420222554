/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Select, { MultiValue } from 'react-select';
import { AppDispatch, AppState } from 'src/store';
import * as providersSlice from '../../store/ServiceProvidersSlice';
import * as providerService from '../../services/providerServices';
import * as serviceServices from '../../services/serviceServices';
import * as branchServices from '../../services/branchServices';
import InputContainer from '@components/form/InputContainer';
import FormButton from '@components/form/FormButton';
import DeleteButton from '@components/form/DeleteButton';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import { getAddedBranches, getRemovedBranches } from '@utils/branches';
import { getAddedServices, getRemovedServices } from '@utils/services';
import { ServiceOptions } from 'src/interfaces/services';
import { BranchOptions } from 'src/interfaces/branches';
import { Provider } from 'src/interfaces/providers';

type Props = {
  onHide: () => void
  show: boolean
  provider: Provider
}

const EditAndDeleteProviderModal = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>()
  const { provider } = props
  const [serviceProvider, setServiceProvider] = useState(provider)
  const {offset, limit} = useSelector((state: AppState) => state.providers)

  const [selectedBranches, setSelectedBranches] = useState<MultiValue<BranchOptions>>([])
  const [existingBranches, setExistingBranches] = useState<BranchOptions[]>([])
  
  const [selectedServices, setSelectedServices] = useState<MultiValue<ServiceOptions>>([])
  const [existingServices, setExistingServices] = useState<ServiceOptions[]>([])

  const [allBranches, setAllBranches] = useState<MultiValue<BranchOptions>>([])
  const [allServices, setAllServices] = useState<MultiValue<ServiceOptions>>([])

  const [addedBranches, setAddedBranches] = useState<MultiValue<BranchOptions>>([])
  const [removedBranches, setRemovedBranches] = useState<MultiValue<BranchOptions>>([])
  const [addedServices, setAddedServices] = useState<MultiValue<ServiceOptions>>([])
  const [removedServices, setRemovedServices] = useState<MultiValue<ServiceOptions>>([])
  const [error, setError] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setServiceProvider(provider)
  }, [provider])

  useEffect(() => {
    const getProvider = async () => {
      const data = await providerService.getProvider(provider.id as number)
      if (data) {
        setLoaded(true)
      }
      const providerServices = data.services.map((eachService: { id: number; name: string }) => {
        const serviceOptionsDropdown = {} as ServiceOptions
        serviceOptionsDropdown.service_id = eachService.id as number
        serviceOptionsDropdown.label = eachService.name
        serviceOptionsDropdown.value = eachService.name.toLowerCase()

        return serviceOptionsDropdown
      })

      const providerBranches = data.branches.map((eachBranch: { id: number; name: string }) => {
        const branchOptionsDropdown = {} as BranchOptions
        branchOptionsDropdown.branch_id = eachBranch.id as number
        branchOptionsDropdown.label = eachBranch.name
        branchOptionsDropdown.value = eachBranch.name.toLowerCase()

        return branchOptionsDropdown
      })

      setExistingBranches(providerBranches);
      setSelectedBranches(providerBranches);

      setExistingServices(providerServices)
      setSelectedServices(providerServices)
    }
    getProvider()
  }, [])

  useEffect(() => {
    const serviceAndBranch = async () => {
      const services = await serviceServices.getAllServices()
      const  transformedServices = services.map((eachService: { id: number; name: string }) => {
        const allServiceOptionsDropdown = {} as ServiceOptions
        allServiceOptionsDropdown.service_id = eachService.id as number
        allServiceOptionsDropdown.label = eachService.name
        allServiceOptionsDropdown.value = eachService.name.toLowerCase()

        return allServiceOptionsDropdown
      })

      const branches = await branchServices.getAllBranches()
      const  transformedBranches = branches.map((eachBranch: { id: number; name: string }) => {
        const allBranchOptionsDropdown = {} as BranchOptions
        allBranchOptionsDropdown.branch_id = eachBranch.id as number
        allBranchOptionsDropdown.label = eachBranch.name
        allBranchOptionsDropdown.value = eachBranch.name.toLowerCase()

        return allBranchOptionsDropdown
      })

      setAllServices(transformedServices)
      setAllBranches(transformedBranches)
    }
    serviceAndBranch()
  }, [])

  const updateBranchOptions = (branches: MultiValue<BranchOptions>) => {
    setSelectedBranches(branches)
    
    const newlyAddedBranches = getAddedBranches(existingBranches, branches)
    const newlyRemovedBranches = getRemovedBranches(existingBranches, branches)
    setAddedBranches(newlyAddedBranches)
    setRemovedBranches(newlyRemovedBranches)
  }
  const updateServiceOptions = (services: MultiValue<ServiceOptions>) => {
    setSelectedServices(services)

    const newlyAddedServices = getAddedServices(existingServices, services)
    const newlyRemovedServices = getRemovedServices(existingServices, services)
    setAddedServices(newlyAddedServices)
    setRemovedServices(newlyRemovedServices)
  }

  const editServiceProvider = async () => {
    setIsSaving(true)
    if (
      serviceProvider.name.length > 0 &&
      serviceProvider.desc.length > 0 &&
      serviceProvider.email.toString().trim() !== '' &&
      serviceProvider.client_limit.toString().trim() !== ''
    ) {
      const updatedProvider = await providerService.updateProvider(serviceProvider)
        for (const eachService of removedServices) {
          const providerServices = {
            service_id: eachService.service_id,
            provider_id: serviceProvider.id as number,
          }
          await providerService.deleteProviderService(providerServices)
        }

        for (const eachBranch of removedBranches) {
          const providerBranches = {
            branch_id: eachBranch.branch_id,
            provider_id: serviceProvider.id as number,
          }
          await providerService.deleteProviderBranch(providerBranches)
        }

        for (const eachService of addedServices) {
          const providerServices = {
            service_id: eachService.service_id,
            provider_id: serviceProvider.id as number,
          }
          await providerService.updateProviderServices(
            providerServices,
          )
        }

        for (const eachBranch of addedBranches) {
          const providerBranches = {
            branch_id: eachBranch.branch_id,
            provider_id: serviceProvider.id as number,
          }
           await providerService.updateProviderBranches(
            providerBranches,
          )
        }

        dispatch(providersSlice.selectProvider(updatedProvider))

        const providerData = await providerService.getProvider(provider.id as number)
        dispatch(providersSlice.setProviderServices(providerData.services))
        dispatch(providersSlice.setProviderBranches(providerData.branches))

        setIsSaving(false)
        setError(false)
        props.onHide()
    } else {
      setIsSaving(false)
      setError(false)
      props.onHide()
    }
  }
  const deleteServiceProvider = async () => {
    setIsDeleting(true)
    const result = await providerService.deleteProvider(serviceProvider)
    if (result && result.status === 204) {
      const providers = await providerService.getAllProviders(offset,limit)
      dispatch(providersSlice.selectProvider(providers[0]))
      dispatch(providersSlice.allProviders(providers))

      setIsDeleting(false)
      props.onHide()
    }
  }

  return (
    <Modal size='lg' {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Edit Service Provider Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loaded && (
          <>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='name' label='Provider Name' required={true}>
                  <input
                    type='text'
                    defaultValue={serviceProvider.name}
                    onChange={(item) =>
                      setServiceProvider({
                        ...serviceProvider,
                        name: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='code' label='Provider Code' required={true}>
                  <input
                    type='text'
                    defaultValue={serviceProvider.code}
                    onChange={(item) =>
                      setServiceProvider({ ...serviceProvider, code: item.target.value })
                    }
                  />
                </InputContainer>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='email' label='Email' required={true}>
                  <input
                    type='email'
                    defaultValue={serviceProvider.email}
                    onChange={(item) =>
                      setServiceProvider({
                        ...serviceProvider,
                        email: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='phone' label='Phone Number'>
                  <input
                    type='number'
                    defaultValue={serviceProvider.phone}
                    onChange={(item) =>
                      setServiceProvider({ ...serviceProvider, phone: item.target.value })
                    }
                  />
                </InputContainer>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='client' label='Client Limit' required={true}>
                  <input
                    type='number'
                    defaultValue={serviceProvider.client_limit}
                    onChange={(item) =>
                      setServiceProvider({
                        ...serviceProvider,
                        client_limit: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='image' label='Provider Image'>
                  <input
                    type='text'
                    defaultValue={serviceProvider.provider_image}
                    onChange={(item) =>
                      setServiceProvider({
                        ...serviceProvider,
                        provider_image: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='services' label='Services'>
                  <Select
                    instanceId='Service'
                    isMulti
                    value={selectedServices}
                    onChange={(service) => updateServiceOptions(service)}
                    options={allServices}
                    className='select'
                    classNamePrefix='select'
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='location' label='Branches'>
                  <Select
                    instanceId='Branches'
                    isMulti
                    value={selectedBranches}
                    onChange={(branch) => updateBranchOptions(branch)}
                    options={allBranches}
                    className='select'
                    classNamePrefix='select'
                  />
                </InputContainer>
              </div>
            </div>
            <InputContainer name='Description' label='Description' required={true}>
              <textarea
                defaultValue={provider.desc}
                onChange={(item) => setServiceProvider({ ...provider, desc: item.target.value })}
                rows={2}
              ></textarea>
            </InputContainer>
          </>
        )}
        <div className='text-danger text-center my-2'>
          {error && 'One or more items is required'}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {isDeleting ? (
          <LoadingSpinner />
        ) : (
          <DeleteButton name='Service Provider' onClick={deleteServiceProvider}>
            Delete
          </DeleteButton>
        )}
        <FormButton className='ms-auto' loading={isSaving} onClick={editServiceProvider}>Save</FormButton>
      </Modal.Footer>
    </Modal>
  )
}
export default EditAndDeleteProviderModal